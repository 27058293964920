var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { invariant } from '../../utils/invariant';
export var ApplicationContext = React.createContext(undefined);
export var ApplicationProvider = function (_a) {
    var children = _a.children, application = _a.application;
    return (_jsx(ApplicationContext.Provider, __assign({ value: application }, { children: children })));
};
export function useApplication() {
    var application = useContext(ApplicationContext);
    invariant(application, 'Application is undefined');
    return application;
}
export function useOrder() {
    var application = useApplication();
    var order = application.orderFacade.order;
    invariant(order, 'Order is null');
    return order;
}
export function useStepController() {
    var application = useApplication();
    return application.stepController;
}
