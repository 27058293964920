import { merge } from '../../utils/merge';
import { notAuthorizedUrl } from './const';
import { storage } from '../storage';
export var createHeaders = function (shouldAddAuth) {
    var token = storage.TOKEN.get();
    var apiKey = storage.API_KEY.get();
    var tokenHeader = {};
    if (shouldAddAuth) {
        tokenHeader = token ? { Authorization: "Bearer ".concat(token) } : {};
    }
    var apiKeyHeader = { 'X-Merchant-Key': apiKey };
    return merge(tokenHeader, apiKeyHeader);
};
export var handleRequest = function (config) {
    var headers = createHeaders(!notAuthorizedUrl.includes(config.url));
    return merge({ headers: headers }, config);
};
