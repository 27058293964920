import axios from 'axios';
import { handleRequest } from './interceptors';
import { notAuthorizedUrl } from './const';
import { storage } from '../storage';
var httpClientInstance = axios.create({
    baseURL: "".concat(process.env.REACT_APP_API_HOST, "/"),
});
httpClientInstance.interceptors.request.use(handleRequest);
var handleError = function (err) {
    var errorObject = JSON.parse(JSON.stringify(err));
    var methodURL = errorObject.config.url;
    var isCurrentMethodNotAuth = Boolean(notAuthorizedUrl.find(function (item) { return item.includes(methodURL); }));
    if (errorObject.status === 401 && !isCurrentMethodNotAuth) {
        storage.clearAll();
        window.location.reload();
    }
    throw err;
};
var handleResponse = function (res) {
    return res;
};
httpClientInstance.interceptors.response.use(handleResponse, handleError);
export var httpClient = httpClientInstance;
