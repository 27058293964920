var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState, } from 'react';
import { getCountriesWithPhone, } from '../../Services/methods/countriesWithPhone';
var CountriesWithPhoneContext = createContext(undefined);
export var CountriesWithPhoneProvider = function (_a) {
    var children = _a.children;
    var _b = useState(), countriesWithPhone = _b[0], setCountriesWithPhone = _b[1];
    useEffect(function () {
        getCountriesWithPhone().then(function (res) { return setCountriesWithPhone(res); });
    }, []);
    return (_jsx(CountriesWithPhoneContext.Provider, __assign({ value: countriesWithPhone }, { children: children })));
};
export function useCountriesWithPhone() {
    return useContext(CountriesWithPhoneContext);
}
