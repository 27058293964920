var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useRef, } from 'react';
import { observer } from 'mobx-react-lite';
import { invariant } from '../../utils/invariant';
import { useApplication } from './ApplicationContext';
import { useNFT, useSettings } from './SettingsContext';
import { useMerchant } from './MerchantContext';
import { storage } from '../../Services/storage';
var AnalyticsContext = React.createContext(undefined);
export var AnalyticsContextProvider = observer(function (_a) {
    var children = _a.children;
    var merchant = useMerchant();
    var analyticsInfo = useSettings().analyticsInfo;
    var order = useApplication().orderFacade.order;
    var isNft = useNFT();
    var oldTrackProperties = useRef();
    var defaultProps = useMemo(function () { return ({
        merchant_name: merchant.title,
        from_currency: order === null || order === void 0 ? void 0 : order.amounts.from.currency.a3,
        from_amount: order === null || order === void 0 ? void 0 : order.amounts.from.amount,
        to_currency: order === null || order === void 0 ? void 0 : order.amounts.to.currency.a3,
        to_amount: order === null || order === void 0 ? void 0 : order.amounts.to.amount,
        exchange_rate: order === null || order === void 0 ? void 0 : order.rate,
        screen_height: analyticsInfo
            ? analyticsInfo.screenHeight
            : window.innerHeight,
        screen_width: analyticsInfo
            ? analyticsInfo.screenWidth
            : window.innerWidth,
        merchant_url: analyticsInfo === null || analyticsInfo === void 0 ? void 0 : analyticsInfo.merchantUrl,
        widget_type: isNft ? 'NFT' : 'STANDARD',
        version: 'v2',
        order_id: storage.ORDER_ID.get(),
        direction: 'from',
    }); }, [
        analyticsInfo,
        isNft,
        merchant.title,
        order === null || order === void 0 ? void 0 : order.amounts.from.amount,
        order === null || order === void 0 ? void 0 : order.amounts.from.currency.a3,
        order === null || order === void 0 ? void 0 : order.amounts.to.amount,
        order === null || order === void 0 ? void 0 : order.amounts.to.currency.a3,
        order === null || order === void 0 ? void 0 : order.rate,
    ]);
    var identify = useCallback(function (properties) {
        if (process.env.REACT_APP_ENV_NAME === 'LOCAL') {
            return;
        }
        var userUid = storage.USER_UID.get();
        if (window.analytics) {
            window.analytics.identify(userUid, properties);
        }
        else {
            // eslint-disable-next-line no-console
            console.error('Analytics is not defined');
        }
    }, []);
    var track = useCallback(function (event, properties, propertiesWithSave) {
        // if (process.env.REACT_APP_ENV_NAME === 'LOCAL') {
        //   return;
        // }
        if (window.analytics) {
            window.analytics.track(event, __assign(__assign(__assign(__assign({}, defaultProps), oldTrackProperties.current), properties), propertiesWithSave));
            if (propertiesWithSave) {
                oldTrackProperties.current = __assign(__assign({}, oldTrackProperties.current), propertiesWithSave);
            }
        }
        else {
            // eslint-disable-next-line no-console
            console.error('Analytics is not defined');
        }
    }, [defaultProps]);
    return (_jsx(AnalyticsContext.Provider, __assign({ value: { identify: identify, track: track } }, { children: children })));
});
export function useAnalytics() {
    var analytics = useContext(AnalyticsContext);
    invariant(analytics, 'useAnalytics should be inside AnalyticsContextProvider');
    return analytics;
}
