var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Header.module.css';
import { forwardRef, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Burger } from './Burger';
import { BackArrow } from './BackArrow';
import { Arrow } from './Arrow';
import { observer } from 'mobx-react-lite';
import { useApplication } from '../../Contexts/ApplicationContext';
import { useNFT } from '../../Contexts/SettingsContext';
import { useAnalytics } from '../../Contexts/AnalyticsContext';
export var Header = observer(forwardRef(function Header(props, ref) {
    var _a, _b, _c;
    var theme = useTheme();
    var _d = useApplication().stepController, goBack = _d.goBack, currentStep = _d.currentStep, isCanGoBack = _d.isCanGoBack;
    var track = useAnalytics().track;
    var smartContractData = useNFT();
    var exchangeInfo = useApplication().orderFacade.exchangeInfo;
    var handleMenuClick = useCallback(function () {
        track('hamburger_menu_viewed', {
            step: currentStep.step,
        });
        props.onBurgerClick();
    }, [currentStep.step, props, track]);
    var chipInfo = useMemo(function () {
        var _a, _b, _c;
        var toInfoNft = smartContractData === null || smartContractData === void 0 ? void 0 : smartContractData.name;
        return (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: styles.pay_info_chip_span }, { children: exchangeInfo.payAmountString })), _jsx(Arrow, {}), toInfoNft ? (_jsx("span", __assign({ className: styles.received_info_chip_span }, { children: toInfoNft }))) : (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: styles.received_info_chip_span, style: { maxWidth: 60 } }, { children: Number(exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts.to.amount) })), '\u00A0', _jsx("span", __assign({ className: styles.received_info_chip_span, style: { maxWidth: 50 } }, { children: (_c = (_b = (_a = exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.currency) === null || _c === void 0 ? void 0 : _c.code }))] }))] }));
    }, [
        exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts.to.amount,
        (_c = (_b = (_a = exchangeInfo === null || exchangeInfo === void 0 ? void 0 : exchangeInfo.amounts) === null || _a === void 0 ? void 0 : _a.to) === null || _b === void 0 ? void 0 : _b.currency) === null || _c === void 0 ? void 0 : _c.code,
        exchangeInfo.payAmountString,
        smartContractData === null || smartContractData === void 0 ? void 0 : smartContractData.name,
    ]);
    var handleBackClick = useCallback(function () {
        props.customBackClick ? props.customBackClick() : goBack();
    }, [goBack, props]);
    var showBackArrow = props.hideBackArrow
        ? false
        : props.customBackClick || isCanGoBack;
    return (_jsxs("header", __assign({ className: styles.header_wrapper, ref: ref }, { children: [props.label ? (_jsx("p", __assign({ className: styles.label }, { children: props.label }))) : (_jsxs(_Fragment, { children: [showBackArrow ? (_jsx(BackArrow, { dataTestId: "back-button", onClick: handleBackClick, fill: theme.colors.textBodyMain })) : (_jsx("div", {})), !props.hideChip && (_jsx("div", __assign({ className: styles.chip, "data-test-id": "header-chip" }, { children: chipInfo })))] })), props.headerChildren, _jsx(Burger, { dataTestId: "button-menu-open", onClick: handleMenuClick })] })));
}));
