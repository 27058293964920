var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from './PageLayout.module.css';
import { useLayoutEffect, useRef, useEffect, useState, } from 'react';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Menu } from '../Menu';
import { Loading } from '../Loading';
import { WIDGET_HEIGHT } from '../../../constanst';
import { ProgressLine } from './ProgressLine';
export var PageLayout = function (_a) {
    var children = _a.children, headerChildren = _a.headerChildren, footer = _a.footer, headerLabel = _a.headerLabel, hideHeaderChip = _a.hideHeaderChip, loading = _a.loading, customBackClick = _a.customBackClick, hideBackArrow = _a.hideBackArrow, disableBodyPadding = _a.disableBodyPadding, className = _a.className, hideProgressLine = _a.hideProgressLine;
    var headerRef = useRef(null);
    var footerRef = useRef(null);
    var bodyWrapperRef = useRef(null);
    var _b = useState(false), menuOpen = _b[0], setMenuOpen = _b[1];
    var _c = useState(), wrapperMaxHeight = _c[0], setWrapperMaxHeight = _c[1];
    useEffect(function () {
        var handleKeyDown = function (e) {
            if (e.key === 'Enter' &&
                (footer === null || footer === void 0 ? void 0 : footer.buttonClick) &&
                !(footer === null || footer === void 0 ? void 0 : footer.actionButtonLoader) &&
                !footer.actionButtonDisable) {
                footer.buttonClick();
            }
        };
        document.addEventListener('keydown', handleKeyDown, true);
        return function () {
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [footer]);
    useLayoutEffect(function () {
        setWrapperMaxHeight(function (prevState) {
            var _a, _b;
            if (((_a = headerRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) && ((_b = footerRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight)) {
                return (WIDGET_HEIGHT -
                    headerRef.current.offsetHeight -
                    footerRef.current.offsetHeight);
            }
            return prevState;
        });
    }, []);
    var bodyStyle = bodyWrapperRef.current &&
        wrapperMaxHeight &&
        bodyWrapperRef.current.offsetHeight > wrapperMaxHeight
        ? {
            maxHeight: wrapperMaxHeight - 4,
            overflow: 'auto',
            paddingBottom: 4,
        }
        : undefined;
    var handleMenuToggle = function () {
        setMenuOpen(function (isOpen) { return !isOpen; });
    };
    var wrapperClassName = "".concat(styles.wrapper, " ").concat((footer === null || footer === void 0 ? void 0 : footer.actionButtonLoader) || (footer === null || footer === void 0 ? void 0 : footer.secondaryButtonLoader)
        ? styles.wrapper_type_blocked
        : '');
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: wrapperClassName }, { children: [!hideProgressLine && _jsx(ProgressLine, {}), _jsx(Header, { ref: headerRef, hideBackArrow: hideBackArrow, customBackClick: customBackClick, onBurgerClick: handleMenuToggle, hideChip: hideHeaderChip, label: headerLabel, headerChildren: headerChildren }), _jsx("div", __assign({ ref: bodyWrapperRef, style: bodyStyle, className: "".concat(styles.body_wrapper, " ").concat(disableBodyPadding ? styles.body_wrapper_without_padding : '', " ").concat(className ? className : '') }, { children: loading ? _jsx(Loading, {}) : children })), _jsx(Footer, __assign({}, footer, { ref: footerRef }))] })), _jsx(Menu, { label: "Menu", isOpen: menuOpen, onClose: handleMenuToggle })] }));
};
export var AnimationPageLayout = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ className: styles.wrapper }, { children: _jsx("div", __assign({ className: "".concat(styles.body_wrapper, " ").concat(styles.body_wrapper_position_relative) }, { children: children })) })));
};
