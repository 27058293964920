var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Footer.module.css';
import { forwardRef } from 'react';
import { Button } from '../BaseComponents/Button';
import { Tooltip } from '../BaseComponents/Tooltip';
import { Caption } from './Caption';
export var Footer = forwardRef(function Footer(_a, ref) {
    var _b = _a.withCaption, withCaption = _b === void 0 ? true : _b, buttonClick = _a.buttonClick, actionButtonLabel = _a.actionButtonLabel, secondaryButtonDisabled = _a.secondaryButtonDisabled, secondaryButtonLabel = _a.secondaryButtonLabel, onSecondaryButtonClick = _a.onSecondaryButtonClick, actionBtnError = _a.actionBtnError, actionButtonDisable = _a.actionButtonDisable, actionButtonLoader = _a.actionButtonLoader, secondaryButtonLoader = _a.secondaryButtonLoader;
    return (_jsxs("footer", __assign({ ref: ref, className: "".concat(styles.wrapper, " ").concat(withCaption ? styles.wrapper_with_caption : '') }, { children: [secondaryButtonLabel && (_jsx(Button, { dataTestId: "secondary-button", disabled: secondaryButtonDisabled, variant: "secondary", label: secondaryButtonLabel, onClick: onSecondaryButtonClick, loader: secondaryButtonLoader })), actionButtonLabel &&
                (actionBtnError ? (_jsx(Tooltip, __assign({ content: actionBtnError, visibleState: Boolean(actionBtnError) }, { children: _jsx(Button, { disabled: actionButtonDisable, dataTestId: "primary-button", onClick: buttonClick, label: actionButtonLabel, loader: actionButtonLoader }) }))) : (_jsx(Button, { disabled: actionButtonDisable, dataTestId: "primary-button", onClick: buttonClick, label: actionButtonLabel, loader: actionButtonLoader }))), withCaption && _jsx(Caption, {})] })));
});
