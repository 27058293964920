var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { useSettings } from '../../Contexts/SettingsContext';
import styles from './PageLayout.module.css';
import { useApplication } from '../../Contexts/ApplicationContext';
import { Page } from '../../../Services/controllers/StepController';
export var ProgressLine = function () {
    var colors = useSettings().colors;
    var isDefaultActionColor = Boolean(!(colors === null || colors === void 0 ? void 0 : colors.main));
    var percent = usePercent();
    if (!percent) {
        return null;
    }
    return (_jsx("div", __assign({ className: styles.line }, { children: _jsx("div", { style: { width: "".concat(percent, "%") }, className: "".concat(styles.percent_line, " ").concat(isDefaultActionColor ? styles.percent_line_default : '') }) })));
};
var percentMap = new Map(Object.entries((_a = {},
    _a[Page.MAIN] = 10,
    _a[Page.PHONE] = 20,
    _a[Page.PHONE_OTP] = 30,
    _a[Page.EMAIL_ADDRESS] = 40,
    _a[Page.EMAIL_ADDRESS_OTP] = 50,
    _a[Page.TARGET_ADDRESS] = 60,
    _a[Page.KYC] = 80,
    _a[Page.CHECK_ORDER] = 100,
    _a)));
var usePercent = function () {
    var stepController = useApplication().stepController;
    var step = stepController.currentStep;
    return percentMap.get(step.step);
};
