var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { invariant } from '../../utils/invariant';
var CurrenciesContext = React.createContext(undefined);
export var CurrenciesContextProvider = function (_a) {
    var children = _a.children, currencies = _a.currencies;
    return (_jsx(CurrenciesContext.Provider, __assign({ value: currencies }, { children: children })));
};
export function useCurrencies() {
    var currencies = useContext(CurrenciesContext);
    invariant(currencies, 'Currencies is not defined');
    return currencies;
}
export function useCurrencyIcon(a3) {
    var _a;
    var currencies = useCurrencies();
    return ((_a = currencies.currenciesMap.get(a3)) === null || _a === void 0 ? void 0 : _a.imageUrl) || '';
}
