export var InitializationErrorsCode;
(function (InitializationErrorsCode) {
    InitializationErrorsCode[InitializationErrorsCode["WrongApiKey"] = 10] = "WrongApiKey";
    InitializationErrorsCode[InitializationErrorsCode["UnknowingError"] = 11] = "UnknowingError";
    InitializationErrorsCode[InitializationErrorsCode["NoOneCurrencyPair"] = 12] = "NoOneCurrencyPair";
    InitializationErrorsCode[InitializationErrorsCode["BlockedUser"] = 13] = "BlockedUser";
    InitializationErrorsCode[InitializationErrorsCode["BlockedGeo"] = 14] = "BlockedGeo";
})(InitializationErrorsCode || (InitializationErrorsCode = {}));
var InitializationError = /** @class */ (function () {
    function InitializationError(_a) {
        var code = _a.code;
        this.code = code;
    }
    Object.defineProperty(InitializationError.prototype, "errorCode", {
        get: function () {
            return this.code;
        },
        enumerable: false,
        configurable: true
    });
    return InitializationError;
}());
export { InitializationError };
