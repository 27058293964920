var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PageManager } from './PageManager';
import { ContextWrapper } from './Contexts/ContextWrapper';
import { ThemeWrapper } from './Styles/ThemeWrapper';
import { ErrorOnLoadPage } from './Pages/ErrorPage';
import './Styles/index.css';
export function renderUI(container, settings, appData) {
    var root = createRoot(container);
    var application = appData[0], currencies = appData[1], merchant = appData[2];
    root.render(_jsx(React.StrictMode, { children: _jsx(ThemeWrapper, __assign({ customColors: settings === null || settings === void 0 ? void 0 : settings.colors }, { children: _jsx(ContextWrapper, __assign({ location: merchant.geo, merchant: merchant, settings: settings, application: application, currencies: currencies }, { children: _jsx(PageManager, {}) })) })) }));
}
export function renderError(container, settings, errorCode) {
    var root = createRoot(container);
    root.render(_jsx(React.StrictMode, { children: _jsx(ThemeWrapper, __assign({ customColors: settings === null || settings === void 0 ? void 0 : settings.colors }, { children: _jsx(ErrorOnLoadPage, { errorCode: errorCode }) })) }));
}
