var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { transparentize, getContrast } from 'polished';
import styled, { ThemeProvider } from 'styled-components';
import { HSLAColor } from './HSLA';
import { checkColorAndConvertToHsla } from './colorConverter/checkColorAndConvertToHsla';
var AppWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: 'Inter', sans-serif;\n  font-weight: 400;\n  position: relative;\n  background: ", ";\n  width: 100%;\n  min-width: 320px;\n  max-width: 400px;\n  border-radius: 24px;\n"], ["\n  font-family: 'Inter', sans-serif;\n  font-weight: 400;\n  position: relative;\n  background: ", ";\n  width: 100%;\n  min-width: 320px;\n  max-width: 400px;\n  border-radius: 24px;\n"])), function (props) { return props.theme.colors.background; });
var defaultColors = {
    main: 'hsla(0, 0%, 2%, 1)',
    background: 'hsla(0, 0%, 100%, 1)',
};
var baseColors = {
    white: '#FFF',
    black: '#000',
    gray50: '#FAFAFA',
    gray100: '#F5F5F5',
    gray200: '#EEEEEE',
    gray300: '#E0E0E0',
    gray400: '#BDBDBD',
    gray500: '#BDBDBD',
    gray600: '#757575',
    gray700: '#616161',
    gray800: '#424242',
    gray900: '#212121',
};
var constantColors = {
    processing: '#FFA400',
    warning: '#ED0A34',
    success: '#58CB4E',
    link: '#F24F21',
};
var brightThemeColors = {
    textBodyMain: baseColors.black,
    textBodyAdd: baseColors.gray700,
    textBodyPlaceholder: transparentize(0.8, baseColors.black),
    bordersDefault: transparentize(0.9, baseColors.black),
    bordersHover: baseColors.black,
    dieDefault: transparentize(0.96, baseColors.black),
    dieHover: transparentize(0.92, baseColors.black),
    textBodySw: transparentize(0.4, baseColors.black),
};
var darkThemeColors = {
    textBodyMain: baseColors.white,
    textBodyAdd: baseColors.gray400,
    textBodyPlaceholder: transparentize(0.8, baseColors.white),
    bordersDefault: transparentize(0.8, baseColors.white),
    bordersHover: baseColors.white,
    dieDefault: transparentize(0.9, baseColors.white),
    dieHover: transparentize(0.8, baseColors.black),
    textBodySw: transparentize(0.4, baseColors.white),
};
function getMainColors(customColors) {
    var mainColors = customColors
        ? __assign(__assign({}, defaultColors), customColors) : defaultColors;
    Object.keys(mainColors).forEach(function (key) {
        try {
            transparentize(0.8, mainColors[key]);
        }
        catch (e) {
            mainColors[key] = defaultColors[key];
            // eslint-disable-next-line no-console
            console.error("Format of colors.".concat(key, " is not valid"));
        }
    });
    return mainColors;
}
function createColors(customColors) {
    var mainColors = getMainColors(customColors);
    var mainColor = new HSLAColor(checkColorAndConvertToHsla(mainColors.main));
    var btnBodyHover;
    var btnBodyPressed;
    if (mainColor.getKey('l') >= 95 || mainColor.getKey('l') <= 10) {
        btnBodyHover =
            mainColor.getKey('l') >= 95
                ? mainColor.sumColor({ l: -5 })
                : mainColor.sumColor({ l: 10 });
        btnBodyPressed = mainColor.getColor();
    }
    else {
        btnBodyHover = mainColor.sumColor({ l: 5 });
        btnBodyPressed = mainColor.sumColor({ l: -5 });
    }
    var colorsFromMain = {
        btnBodyHover: btnBodyHover,
        btnBodyPressed: btnBodyPressed,
        mainFocus: mainColor.sumColor({ a: -0.7 }),
        btnSecondDefault: mainColor.sumColor({ a: -0.9 }),
        btnSecondHover: mainColor.sumColor({ a: -0.8 }),
        btnSecondPressed: mainColor.sumColor({ a: -0.7 }),
        textBtn: 
        // @todo
        getContrast(mainColors.main, baseColors.white) + 0.5 >
            getContrast(mainColors.main, baseColors.black)
            ? baseColors.white
            : baseColors.black,
    };
    var variant = getContrast(mainColors.background, baseColors.white) >
        getContrast(mainColors.background, baseColors.black)
        ? 'dark'
        : 'bright';
    var colorsFromBg = variant === 'dark' ? darkThemeColors : brightThemeColors;
    return __assign(__assign(__assign(__assign(__assign({}, colorsFromMain), colorsFromBg), mainColors), constantColors), { type: variant });
}
function createTheme(customColors) {
    return {
        base: '4px',
        colors: createColors(customColors),
    };
}
export var ThemeWrapper = function (_a) {
    var children = _a.children, customColors = _a.customColors;
    var theme = useMemo(function () { return createTheme(customColors); }, [customColors]);
    useEffect(function () {
        Object.keys(theme.colors).forEach(function (key) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.documentElement.style.setProperty("--".concat(key), theme.colors[key]);
        });
    }, [theme]);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(AppWrapper, { children: _jsxs(_Fragment, { children: [children, _jsx("div", { id: "modal-container-element" })] }) }) })));
};
var templateObject_1;
