var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styles from './BackArrow.module.css';
export var BackArrow = function (_a) {
    var fill = _a.fill, onClick = _a.onClick, dataTestId = _a.dataTestId;
    return (_jsx("svg", __assign({ "data-test-id": dataTestId, onClick: onClick, width: "32", height: "32", viewBox: "0 0 32 32", fill: fill, xmlns: "http://www.w3.org/2000/svg", className: styles.svg }, { children: _jsx("path", { d: "M20.5467 9.88L18.6667 8L10.6667 16L18.6667 24L20.5467 22.12L14.44 16L20.5467 9.88Z" }) })));
};
