var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CaptionLogo } from './CaptionLogo';
import { CaptionPaymentMethods } from './CaptionPaymentMethods';
import styles from './Caption.module.css';
import { useSettings } from '../../../Contexts/SettingsContext';
import { FeatureFlag, useFeatureFlag, } from '../../../Contexts/FeatureFlagContext';
var LINK_FOR_LOGO = 'https://swipelux.com/?utm_source=swipelux_widget&utm_medium=referral&utm_campaign=Inbound';
export var Caption = function () {
    return (_jsxs("div", __assign({ className: styles.caption_wrapper }, { children: [_jsxs("div", __assign({ className: styles.caption_item }, { children: ["Pay with", _jsx(CaptionPaymentMethods, {})] })), _jsx(SignatureLink, {})] })));
};
function SignatureLink() {
    var signature = useSettings().signature;
    var enableWhiteLabel = useFeatureFlag(FeatureFlag.ENABLE_WHITELABEL);
    if (enableWhiteLabel && (!signature || !signature.text)) {
        return null;
    }
    var captionLink = enableWhiteLabel ? signature === null || signature === void 0 ? void 0 : signature.href : LINK_FOR_LOGO;
    var CaptionContent = function () {
        return enableWhiteLabel ? (_jsx("span", __assign({ className: styles.span }, { children: signature === null || signature === void 0 ? void 0 : signature.text.substring(0, 20) }))) : (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: styles.span }, { children: "Powered by" })), _jsx(CaptionLogo, {})] }));
    };
    return (_jsx("a", __assign({ "data-test-id": "button-swipelux", className: styles.caption_link, href: captionLink, target: "_blank", rel: "noreferrer" }, { children: _jsx(CaptionContent, {}) })));
}
