export var rgbOrRgbaToHSLA = function (color) {
    var getColorParams = color
        .substring(color.lastIndexOf('(') + 1, color.lastIndexOf(')'))
        .split(',');
    var isRgba = getColorParams.length === 4;
    var r = Number(getColorParams[0]) / 255;
    var g = Number(getColorParams[1]) / 255;
    var b = Number(getColorParams[2]) / 255;
    var a = isRgba ? Number(getColorParams[3]) : 1;
    var cmin = Math.min(r, g, b);
    var cmax = Math.max(r, g, b);
    var delta = cmax - cmin;
    var h, s, l;
    if (delta == 0) {
        h = 0;
    }
    else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    }
    else if (cmax === g) {
        h = (b - r) / delta + 2;
    }
    else {
        h = (r - g) / delta + 4;
    }
    h = Math.round(h * 60);
    if (h < 0) {
        h += 360;
    }
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = Number((s * 100).toFixed(1));
    l = Number((l * 100).toFixed(1));
    return "hsla(".concat(h, ", ").concat(Math.round(s), "%, ").concat(Math.round(l), "%, ").concat(a, ")");
};
