var ExchangeInfoView = /** @class */ (function () {
    function ExchangeInfoView(exchangeInfo, nftName) {
        this.exchangeInfo = exchangeInfo;
        this.nftName = nftName;
    }
    Object.defineProperty(ExchangeInfoView.prototype, "isDefaultAmount", {
        get: function () {
            return !('rate' in this.exchangeInfo);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "amounts", {
        get: function () {
            return this.exchangeInfo.amounts;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "fee", {
        get: function () {
            return 'fee' in this.exchangeInfo ? this.exchangeInfo.fee : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "rate", {
        get: function () {
            return 'rate' in this.exchangeInfo ? this.exchangeInfo.rate : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "totalFeeString", {
        get: function () {
            if (!this.fee) {
                return '';
            }
            return "".concat(this.fee.total.inFiat.amount, " ").concat(this.fee.total.inFiat.currency);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "zeroFee", {
        get: function () {
            if (!this.fee) {
                return '';
            }
            if (Number(this.fee.total.inFiat.amount) === 0) {
                return "Fees: ".concat(this.totalFeeString);
            }
            else {
                return '';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "atLeastFeeText", {
        get: function () {
            if (!this.fee) {
                return '';
            }
            return "As low as 0.99%";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "networkFee", {
        get: function () {
            if (!this.fee) {
                return '';
            }
            return "0.50 ".concat(this.fee.total.inFiat.currency);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "atLeastFee", {
        get: function () {
            if (!this.fee) {
                return '';
            }
            return "".concat((0.0099 * Math.floor(parseFloat(this.amounts.from.amount))).toFixed(2), " ").concat(this.fee.total.inFiat.currency);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "rateString", {
        get: function () {
            if (!this.rate) {
                return '';
            }
            if (this.nftName) {
                return "".concat(this.nftName, " = ").concat(formatLongNumberToString(this.rate), " ").concat(this.amounts.from.currency.code);
            }
            return "1 ".concat(this.amounts.to.currency.code, " = ").concat(formatLongNumberToString(this.rate), " ").concat(this.amounts.from.currency.code);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "payAmountString", {
        get: function () {
            return "".concat(this.amounts.from.amount, " ").concat(this.amounts.from.currency.code);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "payRealAmountString", {
        get: function () {
            return this.amounts.from.realCurrency && this.amounts.from.realAmount
                ? "".concat(this.amounts.from.realAmount, " ").concat(this.amounts.from.realCurrency.code)
                : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeInfoView.prototype, "receiveAmountString", {
        get: function () {
            return "".concat(formatLongNumberToString(+this.amounts.to.amount), " ").concat(this.amounts.to.currency.code);
        },
        enumerable: false,
        configurable: true
    });
    return ExchangeInfoView;
}());
export { ExchangeInfoView };
function formatLongNumberToString(x, maxLength) {
    if (maxLength === void 0) { maxLength = 8; }
    if (x >= 1) {
        return x.toFixed(2);
    }
    var _a = x.toString().split('.'), fractional = _a[1];
    var fractionalLength = fractional.length;
    return fractionalLength > maxLength ? x.toFixed(maxLength) : x.toString();
}
