import { COLOR_FORMAT_ERROR_MESSAGE, DEFAULT_TEST_COLOR, TYPE_COLORS_REGEX, } from './utils/constants';
import { rgbOrRgbaToHSLA } from './conversions/rgbOrRgbaToHSLA';
import { hexOrHexAToHSLA } from './conversions/hexOrHexAToHSLA';
export var checkColorAndConvertToHsla = function (color, defaultColor) {
    if (defaultColor === void 0) { defaultColor = DEFAULT_TEST_COLOR; }
    var isHexType = TYPE_COLORS_REGEX.HEX.test(color) || TYPE_COLORS_REGEX.HEXA.test(color);
    var isRgbType = TYPE_COLORS_REGEX.RGB.test(color) || TYPE_COLORS_REGEX.RGBA.test(color);
    var isHSLType = TYPE_COLORS_REGEX.HSL.test(color) || TYPE_COLORS_REGEX.HSLA.test(color);
    if (isHexType) {
        return hexOrHexAToHSLA(color);
    }
    if (isRgbType) {
        return rgbOrRgbaToHSLA(color);
    }
    if (isHSLType) {
        return color;
    }
    if (!isHexType && !isRgbType && !isHSLType) {
        // eslint-disable-next-line no-console
        console.error(COLOR_FORMAT_ERROR_MESSAGE(color));
    }
    return defaultColor;
};
