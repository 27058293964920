import { action, computed, makeObservable, observable } from 'mobx';
export var PaymentCardType;
(function (PaymentCardType) {
    PaymentCardType["VISA"] = "Visa";
    PaymentCardType["MASTERCARD"] = "MasterCard";
})(PaymentCardType || (PaymentCardType = {}));
var PaymentController = /** @class */ (function () {
    function PaymentController() {
        var _this = this;
        this.setPayment = function (type) {
            _this.type = type;
        };
        this.type = PaymentCardType.MASTERCARD;
        makeObservable(this, {
            type: observable,
            setPayment: action,
            paymentForService: computed,
        });
    }
    Object.defineProperty(PaymentController.prototype, "paymentForService", {
        get: function () {
            return this.type.toUpperCase();
        },
        enumerable: false,
        configurable: true
    });
    return PaymentController;
}());
export { PaymentController };
