export var hexOrHexAToRgba = function (color) {
    var hexLength = color.length;
    var r, g, b, a;
    if (hexLength === 4) {
        r = '0x' + color[1] + color[1];
        g = '0x' + color[2] + color[2];
        b = '0x' + color[3] + color[3];
    }
    if (hexLength === 5) {
        r = '0x' + color[1] + color[1];
        g = '0x' + color[2] + color[2];
        b = '0x' + color[3] + color[3];
        a = '0x' + color[4] + color[4];
        a = (Number(a) / 255).toFixed(2);
    }
    if (hexLength === 7) {
        r = '0x' + color[1] + color[2];
        g = '0x' + color[3] + color[4];
        b = '0x' + color[5] + color[6];
    }
    if (hexLength === 9) {
        r = '0x' + color[1] + color[2];
        g = '0x' + color[3] + color[4];
        b = '0x' + color[5] + color[6];
        a = '0x' + color[7] + color[8];
        a = (Number(a) / 255).toFixed(2);
    }
    return "rgb".concat(a ? "a" : '', "(").concat(Number(r), ", ").concat(Number(g), ", ").concat(Number(b)).concat(a ? ", ".concat(Number(a)) : '', ")");
};
