var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, Suspense, lazy } from 'react';
import { observer } from 'mobx-react-lite';
import { useApplication } from './Contexts/ApplicationContext';
import { useNFT } from './Contexts/SettingsContext';
import { Page } from '../Services/controllers/StepController';
import { PageLayout } from './Components/PageLayout';
var ThreeDSPaymentPage = lazy(function () { return import('./Pages/PaymentPage/ThreeDSPaymentPage'); });
var PendingTransactionPage = lazy(function () { return import('./Pages/PaymentPage/TransactionPage/PendingTransactionPage'); });
var ErrorTransactionPage = lazy(function () { return import('./Pages/PaymentPage/TransactionPage/ErrorTransactionPage'); });
var AddressPageManager = lazy(function () { return import('./Pages/AddressPage'); });
var KYCPageManager = lazy(function () { return import('./Pages/KYC'); });
var CalculationPage = lazy(function () { return import('./Pages/CalculationPage'); });
var PhonePage = lazy(function () { return import('./Pages/PhonePage'); });
var OtpPhonePage = lazy(function () { return import('./Pages/PhonePage/OtpPhonePage'); });
var EmailPage = lazy(function () { return import('./Pages/EmailPage'); });
var OtpEmailPage = lazy(function () { return import('./Pages/EmailPage/OtpEmailPage'); });
var NFTPage = lazy(function () { return import('./Pages/NFTPage'); });
var CheckOrderManager = lazy(function () { return import('./Pages/CheckOrder'); });
var PaymentPage = lazy(function () { return import('./Pages/PaymentPage'); });
var BlockedPage = lazy(function () { return import('./Pages/BlockedPage'); });
var OrderDetailsPage = lazy(function () { return import('./Pages/OrderDetailsPage'); });
var PreconditionFailedPage = lazy(function () { return import('./Pages/PreconditionFailedPage'); });
var ErrorPage = lazy(function () { return import('./Pages/ErrorPage/ErrorPage'); });
var PaymentWirePending = lazy(function () { return import('./Pages/PaymentWirePending'); });
export var PageManager = observer(function () {
    var stepController = useApplication().stepController;
    var step = stepController.currentStep;
    var smartContractData = useNFT();
    var PageResult = useMemo(function () {
        switch (step.step) {
            case Page.MAIN:
                return smartContractData ? (_jsx(NFTPage, __assign({}, smartContractData))) : (_jsx(CalculationPage, {}));
            case Page.PHONE:
                return _jsx(PhonePage, {});
            case Page.PHONE_OTP:
                return _jsx(OtpPhonePage, {});
            case Page.EMAIL_ADDRESS:
                return _jsx(EmailPage, {});
            case Page.EMAIL_ADDRESS_OTP:
                return _jsx(OtpEmailPage, {});
            case Page.TARGET_ADDRESS:
                return _jsx(AddressPageManager, {});
            case Page.KYC:
                return _jsx(KYCPageManager, {});
            case Page.CHECK_ORDER:
                return _jsx(CheckOrderManager, {});
            case Page.PAYMENT:
                return _jsx(PaymentPage, {});
            case Page.BLOCKED:
                return _jsx(BlockedPage, {});
            case Page.PAYMENT_PENDING:
                return _jsx(PendingTransactionPage, {});
            case Page.PAYMENT_FAILED:
                return (_jsx(ErrorTransactionPage, { errorCode: 'paymentFailureClass' in step ? step.paymentFailureClass : '' }));
            case Page.SUCCESS:
            case Page.ORDER_PLACED:
                return _jsx(OrderDetailsPage, {});
            case Page.FAILED:
                if ('failureType' in step &&
                    step.failureType === 'WITHDRAWAL_PRECONDITION') {
                    return (_jsx(PreconditionFailedPage, { failureMessage: step.failureMessage }));
                }
                return _jsx(OrderDetailsPage, {});
            case Page.PAYMENT_3DS_REQUIRED:
                return _jsx(ThreeDSPaymentPage, {});
            case Page.PAYMENT_WIRE_PENDING:
                return _jsx(PaymentWirePending, {});
            default:
                return _jsx(ErrorPage, {});
        }
    }, [smartContractData, step]);
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLayout, { loading: true, hideBackArrow: true, hideHeaderChip: true }) }, { children: PageResult })));
});
