var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import styled, { useTheme } from 'styled-components';
var OutsideContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-color: ", ";\n  z-index: 1000;\n  top: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  border-radius: 24px;\n\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.4s ease;\n\n  &.show {\n    visibility: visible;\n    opacity: 1;\n  }\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-color: ", ";\n  z-index: 1000;\n  top: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  border-radius: 24px;\n\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.4s ease;\n\n  &.show {\n    visibility: visible;\n    opacity: 1;\n  }\n"])), function (props) { return props.theme.colors.background; });
var InsideContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  transition: all 0.4s ease;\n\n  &.bottom {\n    transform: translateY(600px);\n  }\n\n  &.top {\n    transform: translateY(-600px);\n  }\n\n  &.left {\n    transform: translateX(-100px);\n  }\n\n  &.right {\n    transform: translateX(100px);\n  }\n\n  &.show {\n    transform: translateX(0) translateY(0) !important;\n  }\n"], ["\n  height: 100%;\n  transition: all 0.4s ease;\n\n  &.bottom {\n    transform: translateY(600px);\n  }\n\n  &.top {\n    transform: translateY(-600px);\n  }\n\n  &.left {\n    transform: translateX(-100px);\n  }\n\n  &.right {\n    transform: translateX(100px);\n  }\n\n  &.show {\n    transform: translateX(0) translateY(0) !important;\n  }\n"])));
var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: calc(", " * 8);\n  display: flex;\n  justify-content: space-between;\n"], ["\n  padding: calc(", " * 8);\n  display: flex;\n  justify-content: space-between;\n"])), function (props) { return props.theme.base; });
var StyledSvg = styled.svg(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  transition: all 500ms;\n\n  &:hover {\n    transform: scale3d(1.3, 1.3, 1);\n  }\n"], ["\n  cursor: pointer;\n  transition: all 500ms;\n\n  &:hover {\n    transform: scale3d(1.3, 1.3, 1);\n  }\n"])));
var Close = function (_a) {
    var onClick = _a.onClick, dataTestId = _a.dataTestId;
    var theme = useTheme();
    return (_jsx(StyledSvg, __assign({ "data-test-id": dataTestId, onClick: onClick, width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M25.3334 8.54699L23.4534 6.66699L16.0001 14.1203L8.54675 6.66699L6.66675 8.54699L14.1201 16.0003L6.66675 23.4537L8.54675 25.3337L16.0001 17.8803L23.4534 25.3337L25.3334 23.4537L17.8801 16.0003L25.3334 8.54699Z", fill: theme.colors.textBodyMain }) })));
};
var Label = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.textBodyMain; });
var BodyWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: calc(100% - 96px);\n"], ["\n  height: calc(100% - 96px);\n"])));
export var Modal = function (_a) {
    var isOpen = _a.isOpen, children = _a.children, label = _a.label, onClose = _a.onClose, style = _a.style, _b = _a.anchorOrigin, anchorOrigin = _b === void 0 ? 'bottom' : _b, className = _a.className, dataTestIdPrefix = _a.dataTestIdPrefix;
    if (!document.getElementById('modal-container-element')) {
        return null;
    }
    return ReactDOM.createPortal(_jsx(OutsideContainer, __assign({ className: "".concat(isOpen ? 'show' : ''), "data-test-id": dataTestIdPrefix ? "".concat(dataTestIdPrefix, "-modal") : 'modal' }, { children: _jsxs(InsideContainer, __assign({ style: style, className: "".concat(isOpen ? 'show' : '', " ").concat(anchorOrigin) }, { children: [_jsxs(Header, { children: [label ? _jsx(Label, __assign({ "data-test-id": "header-modal" }, { children: label })) : null, onClose ? (_jsx(Close, { dataTestId: "button-close-modal", onClick: onClose })) : null] }), _jsx(BodyWrapper, __assign({ className: className }, { children: children }))] })) })), 
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('modal-container-element'));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
