var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var localStorageKey = 'WALLETS';
var saveWallets = function (wallets) {
    localStorage.setItem(localStorageKey, "".concat(JSON.stringify(wallets)));
};
var savedWallets = function () {
    var walletString = localStorage.getItem(localStorageKey);
    return walletString ? JSON.parse(walletString) : {};
};
var EVM_BASED_KEY = 'evm_based_currency';
var addressKey = function (currency) { var _a; return ((_a = currency.capabilities) === null || _a === void 0 ? void 0 : _a.evm) ? EVM_BASED_KEY : currency.a3; };
export var savedAddress = function (currency) {
    return savedWallets()[addressKey(currency)] || '';
};
export var saveAddress = function (address, currency) {
    var _a;
    return saveWallets(__assign(__assign({}, savedWallets()), (_a = {}, _a[addressKey(currency)] = address, _a)));
};
