var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getMerchantCurrencies, } from '../../Services/methods/currencies';
import { InitializationError, InitializationErrorsCode, } from '../../Errors/InitializationErrors';
export function getCurrencies(settings) {
    return __awaiter(this, void 0, void 0, function () {
        var result, currenciesMap, filteredPayCurrencies, filteredReceivedCurrencies, to;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getMerchantCurrencies()];
                case 1:
                    result = _a.sent();
                    currenciesMap = new Map();
                    filteredPayCurrencies = result.fiat.filter(function (item) {
                        return settings.availablePayCurrency
                            ? item.code.toLowerCase() === settings.availablePayCurrency.toLowerCase()
                            : true;
                    });
                    filteredReceivedCurrencies = filterReceivedCurrencies(result.crypto, settings.availableReceiveCurrency);
                    __spreadArray(__spreadArray([], filteredPayCurrencies, true), filteredReceivedCurrencies, true).forEach(function (item) {
                        currenciesMap.set(item.a3, item);
                    });
                    to = groupCurrenciesByCode(filteredReceivedCurrencies);
                    if (filteredPayCurrencies.length === 0 ||
                        filteredReceivedCurrencies.length === 0) {
                        throw new InitializationError({
                            code: InitializationErrorsCode.NoOneCurrencyPair,
                        });
                    }
                    return [2 /*return*/, {
                            from: filteredPayCurrencies,
                            to: to,
                            currenciesMap: currenciesMap,
                        }];
            }
        });
    });
}
export function filterReceivedCurrencies(list, availableReceiveCurrency) {
    if (typeof availableReceiveCurrency === 'string') {
        var currencyCode_1 = availableReceiveCurrency.toLowerCase();
        return list.filter(function (item) { return item.code.toLowerCase() === currencyCode_1; });
    }
    if (availableReceiveCurrency && availableReceiveCurrency.code) {
        var currencyCode_2 = availableReceiveCurrency.code.toLowerCase();
        var currencyProtocol_1 = availableReceiveCurrency.protocol;
        return list.filter(function (item) {
            return (item.code.toLowerCase() === currencyCode_2 &&
                (!currencyProtocol_1 || item.protocol === currencyProtocol_1));
        });
    }
    return list;
}
export function groupCurrenciesByCode(list) {
    var temp = [];
    var codes = new Map();
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var currency = list_1[_i];
        var isCodeAlreadyInResult = codes.has(currency.code);
        if (isCodeAlreadyInResult) {
            var codeIndexInResult = codes.get(currency.code);
            temp[codeIndexInResult].push(currency);
        }
        else {
            temp.push([currency]);
            codes.set(currency.code, temp.length - 1);
        }
    }
    return temp.map(function (item) { return (item.length !== 1 ? item : item[0]); });
}
