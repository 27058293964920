var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getCurrencies } from './Models/Currencies/Currencies';
import { getMerchant } from './Models/Merchant/Merchant';
import { getOrderByToken } from './Services/methods/order';
import { OrderFacade, } from './Services/controllers/OrderFacade';
import { StepController } from './Services/controllers/StepController';
import { UserController } from './Services/controllers/UserController';
import { createExchangeController } from './Services/controllers/ExchangeController';
import { OrderController } from './Services/controllers/OrderController';
import { InitializationError, InitializationErrorsCode, } from './Errors/InitializationErrors';
import { BLOCKED_USER_ERROR_CODE } from './Services/apiIntegrations/const';
import { PaymentController } from './Services/controllers/PaymentController';
import { storage } from './Services/storage';
function getOrderOnStart(orderFromSettings) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            token = orderFromSettings || storage.TOKEN.get();
            if (!token) {
                return [2 /*return*/, null];
            }
            return [2 /*return*/, getOrderByToken(token)
                    .then(function (result) {
                    return result.data;
                })
                    .catch(function (err) {
                    if (orderFromSettings) {
                        var code = err.response.status === BLOCKED_USER_ERROR_CODE
                            ? InitializationErrorsCode.BlockedUser
                            : InitializationErrorsCode.UnknowingError;
                        throw new InitializationError({
                            code: code,
                        });
                    }
                    storage.clearAll();
                })];
        });
    });
}
function getStartInfo(settings) {
    return __awaiter(this, void 0, void 0, function () {
        var requiredMethods, res, currencies, merchant, order;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requiredMethods = [
                        getCurrencies(settings),
                        getMerchant(),
                        getOrderOnStart(settings.orderToken),
                    ];
                    return [4 /*yield*/, Promise.all(requiredMethods)];
                case 1:
                    res = (_a.sent());
                    currencies = res[0], merchant = res[1], order = res[2];
                    return [2 /*return*/, { currencies: currencies, merchant: merchant, order: order }];
            }
        });
    });
}
export function createOrderFacade(settings) {
    return __awaiter(this, void 0, void 0, function () {
        var smartContractData, defaultValues, externalId, defaultAmount, _a, currencies, merchant, order, exchangeController, orderController, userController, stepController, paymentController, orderFacade;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    smartContractData = settings.smartContractData, defaultValues = settings.defaultValues, externalId = settings.externalId;
                    defaultAmount = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.fiatAmount;
                    return [4 /*yield*/, getStartInfo(settings)];
                case 1:
                    _a = _b.sent(), currencies = _a.currencies, merchant = _a.merchant, order = _a.order;
                    if (!merchant.geo.allowed) {
                        throw new InitializationError({
                            code: InitializationErrorsCode.BlockedGeo,
                        });
                    }
                    exchangeController = createExchangeController(currencies, order, defaultAmount, smartContractData, merchant.geo.europeanUnion);
                    orderController = new OrderController(order, externalId);
                    userController = new UserController(order === null || order === void 0 ? void 0 : order.userInfo);
                    stepController = new StepController(order === null || order === void 0 ? void 0 : order.currentStep, order === null || order === void 0 ? void 0 : order.stepsHistory, Boolean(settings.orderToken));
                    paymentController = new PaymentController();
                    orderFacade = new OrderFacade(stepController, userController, orderController, exchangeController, paymentController, currencies, defaultValues);
                    return [2 /*return*/, [
                            {
                                stepController: stepController,
                                orderFacade: orderFacade,
                            },
                            currencies,
                            merchant,
                        ]];
            }
        });
    });
}
