var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { estimateRates } from '../methods/order';
import { action, makeObservable, observable } from 'mobx';
var MIN_UPDATE_TIME = 5000;
var ExchangeController = /** @class */ (function () {
    function ExchangeController(estimateRequestBody, amount, smartContractData) {
        this.ttl = MIN_UPDATE_TIME;
        this.smartContractData = smartContractData;
        // @todo надо оставить только exchangeInfo
        this.fee = null;
        this.rate = null;
        this.timeout = undefined;
        this.exchangeInfo = null;
        this.error = false;
        this.exchangeDto = estimateRequestBody;
        this.amounts = amount;
        makeObservable(this, {
            amounts: observable,
            fee: observable,
            rate: observable,
            error: observable,
            exchangeInfo: observable,
            getRates: action,
        });
    }
    ExchangeController.prototype.getRates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, data, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clearTimeout(this.timeout);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, estimateRates(this.exchangeDto)];
                    case 2:
                        res = _a.sent();
                        data = updateAmount(res.data);
                        this.ttl = Math.max(data.rateTtl * 1000, MIN_UPDATE_TIME);
                        this.error = false;
                        this.exchangeInfo = data;
                        this.amounts = data.amounts;
                        this.fee = data.fee;
                        this.rate = data.rate;
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        this.error = true;
                        throw e_1;
                    case 4:
                        this.timeout = setTimeout(function () {
                            _this.getRates();
                        }, this.ttl);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ExchangeController.prototype.changeRequest = function (values) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.exchangeDto = getEstimateRequestDTOFromFields(values);
                        clearTimeout(this.timeout);
                        return [4 /*yield*/, this.getRates()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, {
                                rate: this.rate,
                                amounts: this.amounts,
                                fee: this.fee,
                            }];
                }
            });
        });
    };
    ExchangeController.prototype.startWatching = function () {
        this.getRates();
    };
    ExchangeController.prototype.stopWatching = function () {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    };
    return ExchangeController;
}());
export { ExchangeController };
export function getDefaultAmounts(defaultDto, currencies) {
    if (!defaultDto) {
        throw Error();
    }
    return {
        from: {
            currency: currencies.get(defaultDto.amounts.from.currency),
            amount: 'amount' in defaultDto.amounts.from
                ? "".concat(defaultDto.amounts.from.amount)
                : '0',
        },
        to: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            currency: currencies.get(defaultDto.amounts.to.currency),
            amount: 'amount' in defaultDto.amounts.to
                ? "".concat(defaultDto.amounts.to.amount)
                : '0',
        },
        isError: false,
    };
}
function getEstimateRequestDTOFromFields(_a) {
    var fromAmount = _a.fromAmount, toAmount = _a.toAmount, fromCurrency = _a.fromCurrency, toCurrency = _a.toCurrency, direction = _a.direction;
    var amounts = direction === 'from'
        ? {
            from: { amount: +fromAmount, currency: fromCurrency.a3 },
            to: { currency: toCurrency.a3 },
        }
        : {
            from: { currency: fromCurrency.a3 },
            to: { currency: toCurrency.a3, amount: +toAmount },
        };
    return { amounts: amounts };
}
function updateAmount(data) {
    return __assign(__assign({}, data), { amounts: __assign(__assign({}, data.amounts), { from: __assign(__assign({}, data.amounts.from), { amount: Number(data.amounts.from.amount).toString() }), to: __assign(__assign({}, data.amounts.to), { amount: Number(data.amounts.to.amount).toString() }) }) });
}
export function createExchangeController(currencies, order, defaultAmount, smartContractData, isEuro) {
    var _a;
    if (defaultAmount === void 0) { defaultAmount = 100; }
    var defaultEstimateRequestBody = getDefaultEstimateRequestBody(currencies, order, defaultAmount, smartContractData, isEuro);
    var defaultAmounts = getDefaultAmounts(defaultEstimateRequestBody, currencies.currenciesMap);
    var defaultSmartContractData = smartContractData || ((_a = order === null || order === void 0 ? void 0 : order.metadata) === null || _a === void 0 ? void 0 : _a.smartContractData);
    return new ExchangeController(defaultEstimateRequestBody, defaultAmounts, defaultSmartContractData);
}
export function getDefaultEstimateRequestBody(currencies, order, defaultAmount, smartContractData, isEuro) {
    var _a, _b;
    var currencyCodeByGeo = isEuro ? 'EUR' : 'USD';
    var defaultFromCurrency = ((_a = currencies.currenciesMap.get(currencyCodeByGeo)) === null || _a === void 0 ? void 0 : _a.a3) ||
        currencies.from[0].a3;
    var defaultToCurrency = Array.isArray(currencies.to[0])
        ? currencies.to[0][0].a3
        : currencies.to[0].a3;
    if ((_b = order === null || order === void 0 ? void 0 : order.metadata) === null || _b === void 0 ? void 0 : _b.smartContractData) {
        return {
            amounts: {
                from: {
                    currency: order.amounts.from.currency.a3,
                },
                to: {
                    amount: +order.amounts.to.amount,
                },
            },
            metadata: order.metadata,
        };
    }
    if (order === null || order === void 0 ? void 0 : order.amounts) {
        return {
            amounts: {
                from: {
                    amount: +order.amounts.from.amount,
                    currency: order.amounts.from.currency.a3,
                },
                to: {
                    currency: order.amounts.to.currency.a3,
                },
            },
        };
    }
    if (smartContractData) {
        var amounts = {
            from: {
                currency: defaultFromCurrency,
            },
            to: {
                amount: 1,
            },
        };
        return {
            amounts: amounts,
            metadata: {
                smartContractData: __assign(__assign({}, smartContractData), { imageUrl: smartContractData.imgUrl, imageName: smartContractData.name }),
            },
        };
    }
    return {
        amounts: {
            from: {
                amount: defaultAmount,
                currency: defaultFromCurrency,
            },
            to: {
                currency: defaultToCurrency,
            },
        },
    };
}
