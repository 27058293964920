var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, makeObservable, observable } from 'mobx';
import { setUserToken } from '../userToken';
export var Page;
(function (Page) {
    Page["MAIN"] = "MAIN";
    Page["PHONE"] = "PHONE";
    Page["PHONE_OTP"] = "PHONE_OTP";
    Page["EMAIL_ADDRESS"] = "EMAIL_ADDRESS";
    Page["EMAIL_ADDRESS_OTP"] = "EMAIL_ADDRESS_OTP";
    Page["TARGET_ADDRESS"] = "TARGET_ADDRESS";
    Page["KYC"] = "KYC";
    Page["CHECK_ORDER"] = "CHECK_ORDER";
    Page["PAYMENT"] = "PAYMENT";
    Page["PAYMENT_PENDING"] = "PAYMENT_PENDING";
    Page["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    Page["BLOCKED"] = "BLOCKED";
    Page["ORDER_PLACED"] = "ORDER_PLACED";
    Page["SUCCESS"] = "SUCCESS";
    Page["FAILED"] = "FAILED";
    Page["PAYMENT_3DS_REQUIRED"] = "PAYMENT_3DS_REQUIRED";
    Page["PAYMENT_WIRE_PENDING"] = "PAYMENT_WIRE_PENDING";
})(Page || (Page = {}));
export var MainStep = {
    step: Page.MAIN,
};
export var PhoneStep = {
    step: Page.PHONE,
};
function createInitialStepsHistory(currentStepsHistory, addMainStep) {
    if (addMainStep === void 0) { addMainStep = true; }
    if (!currentStepsHistory) {
        return [];
    }
    if (addMainStep) {
        return __spreadArray([MainStep], currentStepsHistory, true);
    }
    return currentStepsHistory;
}
var StepController = /** @class */ (function () {
    function StepController(startStep, stepsHistory, isOrderToken) {
        var _this = this;
        this.updateStepInfo = function (info) {
            var newStep = info.nextStep;
            var newStepHistory = info.stepsHistory;
            if ('userToken' in newStep && newStep.userToken) {
                setUserToken(newStep.userToken);
            }
            _this.setStep(newStep);
            _this.setStepsHistory(newStepHistory);
        };
        this.setStep = function (step) {
            _this.isCurrentStepInitial = false;
            _this.currentStep = step;
        };
        this.setStepsHistory = function (stepsHistory) {
            if (!stepsHistory) {
                // eslint-disable-next-line no-console
                console.warn('stepsHistory is not defined');
            }
            if (stepsHistory) {
                _this.stepsHistory = _this.isFirstStepAvailable
                    ? __spreadArray([MainStep], stepsHistory, true) : __spreadArray([], stepsHistory, true);
            }
        };
        this.goBack = function () {
            if (_this.stepsHistory.length > 0) {
                var newCurrentStep = _this.stepsHistory[_this.stepsHistory.length - 1];
                var newStepHistory = _this.stepsHistory.slice(0, _this.stepsHistory.length - 1);
                _this.updateStepInfo({
                    nextStep: newCurrentStep,
                    stepsHistory: newStepHistory,
                });
            }
        };
        this.currentStep = startStep || MainStep;
        this.isFirstStepAvailable = !isOrderToken;
        this.stepsHistory = createInitialStepsHistory(stepsHistory, this.isFirstStepAvailable);
        this.isCurrentStepInitial = true;
        makeObservable(this, {
            currentStep: observable,
            updateStepInfo: action,
            stepsHistory: observable,
            isCurrentStepInitial: observable,
        });
    }
    Object.defineProperty(StepController.prototype, "isCanGoBack", {
        get: function () {
            return this.stepsHistory.length !== 0;
        },
        enumerable: false,
        configurable: true
    });
    return StepController;
}());
export { StepController };
