var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import styles from './Menu.module.css';
import { ArrowIcon } from './Icons';
import { useAnalytics } from '../../Contexts/AnalyticsContext';
import { useApplication } from '../../Contexts/ApplicationContext';
export var MenuItem = function (_a) {
    var label = _a.label, Icon = _a.Icon, onClick = _a.onClick, href = _a.href;
    var track = useAnalytics().track;
    var currentStep = useApplication().stepController.currentStep;
    var handleClick = useCallback(function () {
        track('hamburger_menu_clicked', {
            button: label,
            step: currentStep.step,
        });
        onClick === null || onClick === void 0 ? void 0 : onClick();
    }, [currentStep.step, label, onClick, track]);
    var testId = "item-".concat(label.toLowerCase().split(' ').join('-'));
    return (_jsxs("a", __assign({ href: href, target: "_blank", rel: "noreferrer", className: styles.option, "data-test-id": testId, onClick: handleClick }, { children: [Icon, _jsx("span", __assign({ className: styles.option_label }, { children: label })), _jsx(ArrowIcon, { className: styles.arrow_icon })] })));
};
