var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px 32px;\n  border-radius: 8px;\n  background: ", ";\n  color: ", ";\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 24px;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  border: none;\n\n  &:hover {\n    background: ", ";\n  }\n\n  &:active {\n    background: ", ";\n    border: none;\n  }\n\n  &:disabled {\n    pointer-events: none;\n    cursor: not-allowed;\n    opacity: 0.4;\n  }\n\n  &:focus-visible {\n    outline: 2px solid ", ";\n  }\n"], ["\n  padding: 20px 32px;\n  border-radius: 8px;\n  background: ", ";\n  color: ", ";\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 24px;\n  cursor: pointer;\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  border: none;\n\n  &:hover {\n    background: ", ";\n  }\n\n  &:active {\n    background: ", ";\n    border: none;\n  }\n\n  &:disabled {\n    pointer-events: none;\n    cursor: not-allowed;\n    opacity: 0.4;\n  }\n\n  &:focus-visible {\n    outline: 2px solid ", ";\n  }\n"])), function (props) {
    return props.variant === 'primary'
        ? props.theme.colors.main
        : props.theme.colors.btnSecondDefault;
}, function (props) {
    return props.variant === 'primary'
        ? props.theme.colors.textBtn
        : props.theme.colors.main;
}, function (props) {
    return props.variant === 'primary'
        ? props.theme.colors.btnBodyHover
        : props.theme.colors.btnSecondHover;
}, function (props) {
    return props.variant === 'primary'
        ? props.theme.colors.btnBodyPressed
        : props.theme.colors.btnSecondPressed;
}, function (props) { return props.theme.colors.mainFocus; });
var LoaderCircle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 12px;\n  width: 20px;\n  height: 20px;\n\n  border-radius: 50%;\n  border: 2px solid\n    ", ";\n\n  border-color: ", "\n    transparent\n    ", "\n    transparent;\n  animation: lds-dual-ring 1.2s linear infinite;\n\n  @keyframes lds-dual-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  margin-left: 12px;\n  width: 20px;\n  height: 20px;\n\n  border-radius: 50%;\n  border: 2px solid\n    ", ";\n\n  border-color: ", "\n    transparent\n    ", "\n    transparent;\n  animation: lds-dual-ring 1.2s linear infinite;\n\n  @keyframes lds-dual-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"])), function (props) {
    return props.variant === 'primary'
        ? props.theme.colors.textBtn
        : props.theme.colors.main;
}, function (props) {
    return props.variant === 'primary'
        ? props.theme.colors.textBtn
        : props.theme.colors.main;
}, function (props) {
    return props.variant === 'primary'
        ? props.theme.colors.textBtn
        : props.theme.colors.main;
});
export var Button = function (_a) {
    var label = _a.label, onClick = _a.onClick, _b = _a.variant, variant = _b === void 0 ? 'primary' : _b, disabled = _a.disabled, className = _a.className, dataTestId = _a.dataTestId, loader = _a.loader;
    return (_jsxs(StyledButton, __assign({ className: className, variant: variant, onClick: onClick, disabled: disabled, "data-test-id": dataTestId }, { children: [label, loader ? _jsx(LoaderCircle, { variant: variant }) : null] })));
};
var templateObject_1, templateObject_2;
