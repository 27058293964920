import { jsx as _jsx } from "react/jsx-runtime";
import { AMLSvg, CookieSvg, PerksSvg, PrivacySvg, SupportIcon, TermsSvg, } from './Icons';
export var MenuConfig = [
    {
        label: 'Support',
        onClick: function () {
            window.Intercom('showNewMessage');
        },
        Icon: _jsx(SupportIcon, {}),
    },
    {
        label: 'Perks',
        Icon: _jsx(PerksSvg, {}),
        href: 'https://portal.zeroid.org/',
    },
    {
        label: 'Terms of Service',
        href: 'https://swipelux.com/terms_of_use',
        Icon: _jsx(TermsSvg, {}),
    },
    {
        label: 'Privacy Policy',
        href: 'https://swipelux.com/privacy_policy',
        Icon: _jsx(PrivacySvg, {}),
    },
    {
        label: 'AML Policy',
        href: 'https://swipelux.com/aml_policy',
        Icon: _jsx(AMLSvg, {}),
    },
    {
        label: 'Cookies policy',
        href: 'https://swipelux.com/cookie_policy',
        Icon: _jsx(CookieSvg, {}),
    },
];
