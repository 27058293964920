var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ApplicationProvider } from './ApplicationContext';
import { SettingContextProvider } from './SettingsContext';
import { AnalyticsContextProvider } from './AnalyticsContext';
import { FeatureFlagProvider } from './FeatureFlagContext';
import { CurrenciesContextProvider } from './CurrenciesContext';
import { MerchantContextProvider } from './MerchantContext';
import { LocationDataProvider } from './LocationDataContext';
import { CountriesWithPhoneProvider } from './CountriesWithPhoneContext';
export var ContextWrapper = function (_a) {
    var application = _a.application, settings = _a.settings, merchant = _a.merchant, currencies = _a.currencies, children = _a.children, location = _a.location;
    return (_jsx(ApplicationProvider, __assign({ application: application }, { children: _jsx(SettingContextProvider, __assign({ settings: settings }, { children: _jsx(MerchantContextProvider, __assign({ merchant: merchant }, { children: _jsx(CurrenciesContextProvider, __assign({ currencies: currencies }, { children: _jsx(LocationDataProvider, __assign({ locationData: location }, { children: _jsx(CountriesWithPhoneProvider, { children: _jsx(FeatureFlagProvider, { children: _jsx(AnalyticsContextProvider, { children: children }) }) }) })) })) })) })) })));
};
