import { action, makeObservable, observable } from 'mobx';
var UserController = /** @class */ (function () {
    function UserController(_a) {
        var _b = _a === void 0 ? {} : _a, phone = _b.phone, address = _b.address, email = _b.email, subscribe = _b.subscribe;
        this.phone = phone ? (phone[0] === '+' ? phone : "+".concat(phone)) : undefined;
        this.address = address;
        this.email = email;
        this.subscribe = subscribe || false;
        makeObservable(this, {
            phone: observable,
            address: observable,
            email: observable,
            subscribe: observable,
            setField: action,
        });
    }
    UserController.prototype.setField = function (value, fieldName) {
        if (fieldName === 'subscribe') {
            this.subscribe = !!value;
            return;
        }
        this[fieldName] = value;
    };
    return UserController;
}());
export { UserController };
