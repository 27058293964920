var PREFIX = 'Invariant failed';
var isProd = process.env.NODE_ENV === 'production';
export function invariant(condition, message) {
    if (condition) {
        return;
    }
    if (isProd) {
        throw new Error(PREFIX);
    }
    else {
        throw new Error("".concat(PREFIX, ": ").concat(message || 'no message'));
    }
}
