var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { ErrorIcon } from '../PaymentPage/icons';
import { InitializationErrorsCode } from '../../../Errors/InitializationErrors';
import { storage } from '../../../Services/storage';
import { AnimationPageLayout, PageLayout } from '../../Components/PageLayout';
import { CaptionLogo } from '../../Components/Footer/Caption/CaptionLogo';
import styles from './ErrorPage.module.css';
export default function ErrorPage() {
    var reloadApp = useCallback(function () {
        storage.clearAll();
        window.location.reload();
    }, []);
    return (_jsxs(PageLayout, __assign({ footer: { actionButtonLabel: 'Try again', buttonClick: reloadApp } }, { children: [_jsx("div", __assign({ className: styles.icon_wrapper }, { children: _jsx(ErrorIcon, {}) })), _jsx("div", __assign({ className: styles.title }, { children: "Something went wrong" })), _jsx("div", __assign({ className: styles.description }, { children: "Try updating the widget" }))] })));
}
var InitializationErrorsText = (_a = {},
    _a[InitializationErrorsCode.WrongApiKey] = {
        title: 'Sorry, you use wrong API-KEY',
        description: 'You have to check your API-KEY on widget settings',
    },
    _a[InitializationErrorsCode.NoOneCurrencyPair] = {
        title: 'We cannot find any available currency pair',
        description: "Check the widget settings, you may be trying to open a pair that doesn't exist.",
    },
    _a[InitializationErrorsCode.UnknowingError] = {
        title: 'Something went wrong',
        description: 'Try reloading the widget or contact support.',
    },
    _a[InitializationErrorsCode.BlockedUser] = {
        title: 'Transactions are limited',
        description: 'Unfortunately, we cannot process your transaction in accordance with the internal policy of the company.',
    },
    _a[InitializationErrorsCode.BlockedGeo] = {
        title: 'Coming soon to your region!',
        description: 'As soon as we are ready to work in your country, we will definitely let you know.',
        img: './public/asset/errors/world.svg',
    },
    _a);
export var ErrorOnLoadPage = function (_a) {
    var _b = _a.errorCode, errorCode = _b === void 0 ? InitializationErrorsCode.UnknowingError : _b;
    var _c = InitializationErrorsText[errorCode], title = _c.title, description = _c.description, img = _c.img;
    return (_jsx(AnimationPageLayout, { children: _jsxs("div", __assign({ className: styles.init_wrapper }, { children: [img && _jsx("img", { className: styles.img, src: img }), _jsxs("div", __assign({ className: styles.info }, { children: [_jsx("div", __assign({ className: styles.title }, { children: title })), _jsx("div", __assign({ className: styles.description }, { children: description }))] })), _jsxs("a", __assign({ target: "_blank", rel: "noreferrer", href: "https://swipelux.com", className: styles.caption }, { children: [_jsx("span", { children: "Powered by" }), _jsx(CaptionLogo, {})] }))] })) }));
};
