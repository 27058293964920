var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { renderError, renderUI } from './View';
import { MessageTypes } from 'common';
import { createOrderFacade } from './createOrderFacade';
import { InitializationErrorsCode } from './Errors/InitializationErrors';
import { storage } from './Services/storage';
import './sentryInit';
import { networkInfo, languageAndTimeZone } from './analiticsUtils';
var BootstrapWidget = function (container, settings) {
    var _a, _b;
    window.analytics.load(process.env.REACT_APP_CUSTOMER_IO_API_KEY);
    window.analytics.page();
    var bootstrapTimestamp = new Date().getTime();
    var initBootstrapDelta = (settings === null || settings === void 0 ? void 0 : settings.analyticsInfo)
        ? bootstrapTimestamp - (settings === null || settings === void 0 ? void 0 : settings.analyticsInfo.initTimestamp)
        : 'unknown';
    window.analytics.track('Widget Initialized', __assign(__assign(__assign({}, networkInfo()), languageAndTimeZone()), { initBootstrapDelta: initBootstrapDelta, bootstrapTimestamp: bootstrapTimestamp, initTimestamp: (_a = settings === null || settings === void 0 ? void 0 : settings.analyticsInfo) === null || _a === void 0 ? void 0 : _a.initTimestamp, merchantUrl: (_b = settings === null || settings === void 0 ? void 0 : settings.analyticsInfo) === null || _b === void 0 ? void 0 : _b.merchantUrl }));
    if (settings.forceNewTransaction) {
        storage.clearAll();
    }
    if (settings.orderToken) {
        storage.clearAll();
        storage.TOKEN.set(settings.orderToken);
    }
    storage.API_KEY.set(settings.apiKey);
    createOrderFacade(settings)
        .then(function (result) {
        renderUI(container, settings, result);
    })
        .catch(function (err) {
        var errorCode = err.errorCode || InitializationErrorsCode.UnknowingError;
        renderError(container, settings, errorCode);
    })
        .finally(function () {
        container.classList.remove('loader');
    });
};
var rootEl = document.getElementById('root');
window.addEventListener('message', function (event) {
    var data = event.data || {};
    if (data.type === MessageTypes.START_APPLICATION && rootEl) {
        var settings = data.payload;
        BootstrapWidget(rootEl, settings);
    }
});
