var HSLAColor = /** @class */ (function () {
    function HSLAColor(color) {
        var _this = this;
        this.getKey = function (key) {
            return _this.color[key];
        };
        this.getColor = function () {
            return hslaObjectToString(_this.color);
        };
        this.sumColor = function (color) {
            return hslaObjectToString(sumHslaObjects(_this.color, color));
        };
        this.color = hslaStringToObject(color);
    }
    return HSLAColor;
}());
export { HSLAColor };
function hslaObjectToString(color) {
    var h = color.h, s = color.s, l = color.l, a = color.a;
    return "hsla(".concat(h, ",").concat(s, "%,").concat(l, "%,").concat(a, ")");
}
function hslaStringToObject(color) {
    var _a = color
        .substring(color.indexOf('(') + 1, color.indexOf(')'))
        .split(',')
        .map(function (item) { return parseInt(item); }), h = _a[0], s = _a[1], l = _a[2], _b = _a[3], a = _b === void 0 ? 1 : _b;
    return {
        h: h,
        s: s,
        l: l,
        a: a,
    };
}
function sumHslaObjects(mainColor, increment) {
    var result = { h: 0, l: 0, s: 0, a: 0 };
    Object.keys(mainColor).forEach(function (key) {
        var value = mainColor[key] + (increment[key] || 0);
        var isPercentKey = key === 'l' || key === 's';
        switch (key) {
            case 'h':
                result[key] = value;
                break;
            case 's':
            case 'l':
                result[key] = valueInRange(value, 100);
                break;
            case 'a':
                result[key] = valueInRange(value, 1);
        }
    });
    return result;
}
function valueInRange(value, endRange, startRange) {
    if (startRange === void 0) { startRange = 0; }
    return value >= endRange
        ? endRange
        : value <= startRange
            ? startRange
            : value;
}
