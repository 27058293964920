var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { invariant } from '../../utils/invariant';
import { useApplication } from './ApplicationContext';
var SettingContext = createContext(null);
export var SettingContextProvider = function (_a) {
    var settings = _a.settings, children = _a.children;
    return (_jsx(SettingContext.Provider, __assign({ value: settings }, { children: children })));
};
export function useSettings() {
    var settings = useContext(SettingContext);
    invariant(settings, 'Settings is not provide');
    return settings;
}
export function getNonEditableDefaultValue(v) {
    return v && v.value && v.editable === false ? v.value : undefined;
}
export function useNFT() {
    var NFTInfo = useApplication().orderFacade.NFTInfo;
    return NFTInfo || null;
}
