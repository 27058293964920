var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
var Loader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  background-color: ", ";\n\n  &::after {\n    content: '';\n    width: 50px;\n    height: 50px;\n    border: 8px solid ", ";\n    border-color: ", " transparent\n      ", " transparent;\n    border-radius: 50%;\n    animation: lds-dual-ring 1.2s linear infinite;\n    position: absolute;\n    top: calc(50% - 45px);\n    right: calc(50% - 25px);\n  }\n\n  @keyframes lds-dual-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  height: 100%;\n  background-color: ", ";\n\n  &::after {\n    content: '';\n    width: 50px;\n    height: 50px;\n    border: 8px solid ", ";\n    border-color: ", " transparent\n      ", " transparent;\n    border-radius: 50%;\n    animation: lds-dual-ring 1.2s linear infinite;\n    position: absolute;\n    top: calc(50% - 45px);\n    right: calc(50% - 25px);\n  }\n\n  @keyframes lds-dual-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"])), function (props) { return props.theme.colors.background; }, function (props) { return props.theme.colors.main; }, function (props) { return props.theme.colors.main; }, function (props) { return props.theme.colors.main; });
export var Loading = function () {
    return _jsx(Loader, {});
};
var templateObject_1;
