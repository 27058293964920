var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Tooltip.module.css';
import { useEffect, useRef, useState } from 'react';
var HALF_MAIN_CONTENT_PADDING = 16; //px
var MAXIMUM_POSSIBLE_TOOLTIP_WIDTH = 336; //px
var calculateElementPosition = function (offset) {
    return "calc(50% + ".concat(offset, "px)");
};
export var Tooltip = function (_a) {
    var visibleState = _a.visibleState, children = _a.children, content = _a.content, onClick = _a.onClick, width = _a.width, _b = _a.position, position = _b === void 0 ? 'top' : _b, _c = _a.defaultVisible, defaultVisible = _c === void 0 ? false : _c, top = _a.top;
    var _d = useState(defaultVisible), isVisible = _d[0], setIsVisible = _d[1];
    var tooltipRef = useRef(null);
    var cornerRef = useRef(null);
    useEffect(function () {
        var tooltipEl = tooltipRef.current;
        var cornerEl = cornerRef.current;
        if (tooltipEl && cornerEl) {
            var bodyWidth = document.body.clientWidth;
            var _a = tooltipEl.getBoundingClientRect(), left = _a.left, right = _a.right, width_1 = _a.width;
            if (width_1 > MAXIMUM_POSSIBLE_TOOLTIP_WIDTH) {
                tooltipEl.style.width = "".concat(MAXIMUM_POSSIBLE_TOOLTIP_WIDTH, "px");
                tooltipEl.style.whiteSpace = 'wrap';
            }
            if (left < 0) {
                var tooltipElOffset = HALF_MAIN_CONTENT_PADDING - left;
                var cornerElOffset = left - HALF_MAIN_CONTENT_PADDING;
                tooltipEl.style.left = calculateElementPosition(tooltipElOffset);
                cornerEl.style.left = calculateElementPosition(cornerElOffset);
            }
            if (right > bodyWidth) {
                var tooltipElOffset = -HALF_MAIN_CONTENT_PADDING - (right - bodyWidth);
                var cornerElOffset = HALF_MAIN_CONTENT_PADDING + (right - bodyWidth);
                tooltipEl.style.left = calculateElementPosition(tooltipElOffset);
                cornerEl.style.left = calculateElementPosition(cornerElOffset);
            }
        }
    }, [isVisible, visibleState]);
    var tooltipVisible = isVisible || visibleState ? styles.tooltip__component_type_visible : '';
    var calculateComponentClassName = "".concat(styles.tooltip__component, " ").concat(styles["tooltip__component_type_".concat(position)], " ").concat(tooltipVisible);
    var calculateCornerClassName = "".concat(styles.tooltip__corner, " ").concat(styles["tooltip__corner_type_".concat(position)]);
    return (_jsxs("div", __assign({ className: styles.tooltip__wrapper, onMouseOver: onClick ? undefined : function () { return setIsVisible(true); }, onMouseOut: onClick ? undefined : function () { return setIsVisible(false); }, onClick: onClick }, { children: [children, _jsxs("div", __assign({ className: calculateComponentClassName, ref: tooltipRef, style: {
                    width: width,
                    whiteSpace: "".concat(width ? 'normal' : 'nowrap'),
                    top: top,
                } }, { children: [content, _jsx("div", { className: calculateCornerClassName, ref: cornerRef })] }))] })));
};
