var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Modal } from '../Modals/Modal';
import { MenuItem } from './MenuItem';
import { MenuConfig } from './Config';
export var Menu = function (props) {
    useEffect(function () {
        if (props.isOpen) {
            window.Intercom('boot', {
                app_id: 'no0vwrwo',
                // todo: We can pass user information
                //Website visitor so may not have any user related info
                // email: 'example@example.com',
                // user_id: 'abc123',
                // created_at: 1234567890,
            });
        }
        else {
            window.Intercom('shutdown');
        }
    }, [props.isOpen]);
    return (_jsx(Modal, __assign({ anchorOrigin: "top" }, props, { dataTestIdPrefix: "menu" }, { children: MenuConfig.map(function (item) { return (_jsx(MenuItem, __assign({}, item), item.label)); }) })));
};
