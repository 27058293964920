var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var StorageKeys;
(function (StorageKeys) {
    StorageKeys["TOKEN"] = "TOKEN";
    StorageKeys["ORDER_ID"] = "ORDER_ID";
    StorageKeys["USER_UID"] = "USER_UID";
    StorageKeys["API_KEY"] = "API_KEY";
})(StorageKeys || (StorageKeys = {}));
var StorageItem = /** @class */ (function () {
    function StorageItem(key) {
        this.key = key;
        try {
            this.value = (sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem(this.key)) || undefined;
            // eslint-disable-next-line no-empty
        }
        catch (e) { }
    }
    StorageItem.prototype.get = function () {
        return this.value;
    };
    StorageItem.prototype.set = function (value) {
        try {
            sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem(this.key, value);
            // eslint-disable-next-line no-empty
        }
        catch (e) {
        }
        finally {
            this.value = value;
        }
    };
    StorageItem.prototype.remove = function () {
        try {
            sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.removeItem(this.key);
            // eslint-disable-next-line no-empty
        }
        catch (e) {
        }
        finally {
            this.value = undefined;
        }
    };
    return StorageItem;
}());
var storagesFromKeys = Object.keys(StorageKeys).reduce(function (previousValue, currentValue) {
    var key = currentValue;
    previousValue[key] = new StorageItem(key);
    return previousValue;
}, {});
var createStorage = function () {
    var clearAll = function () {
        Object.keys(storagesFromKeys).forEach(function (key) {
            storagesFromKeys[key].remove();
        });
    };
    return __assign(__assign({}, storagesFromKeys), { clearAll: clearAll });
};
export var storage = createStorage();
